// Generated by Framer (c75d380)

import { addFonts, cx, CycleVariantState, SVG, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./AWki6lWtj-0.js";

const cycleOrder = ["ICWJFgnix"];

const serializationHash = "framer-imhen"

const variantClassNames = {ICWJFgnix: "framer-v-663we"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const valuesByLocaleId = {eWWczOETO: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "ICWJFgnix", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-663we", className, classNames)} data-framer-name={"Variant 1"} initial={variant} layoutDependency={layoutDependency} layoutId={"ICWJFgnix"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><SVG className={"framer-1e24eql"} data-framer-name={"shape8"} fill={"black"} intrinsicHeight={24} intrinsicWidth={24} layoutDependency={layoutDependency} layoutId={"Qn4uAVeyH"} svg={"<svg width=\"24\" height=\"24\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M17.735 8.722a.66.66 0 0 1 .32.458l.692 3.95-3.772 6.848a.674.674 0 0 1-.408.315.657.657 0 0 1-.503-.065l-9.069-5.236a.657.657 0 0 1-.308-.403.674.674 0 0 1 .07-.51l3.69-6.395 4.12-1.672a.66.66 0 0 1 .557.047Z\" stroke=\"#EDE3D1\" stroke-width=\"1.2\" fill=\"#EDE3D1\"/></svg>"} withExternalLayout/></motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-imhen[data-border=\"true\"]::after, .framer-imhen [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-imhen.framer-165u6ck, .framer-imhen .framer-165u6ck { display: block; }", ".framer-imhen.framer-663we { height: 24px; overflow: visible; position: relative; width: 24px; }", ".framer-imhen .framer-1e24eql { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 24px); left: 0px; position: absolute; right: 0px; top: 0px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 24
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerAWki6lWtj: React.ComponentType<Props> = withCSS(Component, css, "framer-imhen") as typeof Component;
export default FramerAWki6lWtj;

FramerAWki6lWtj.displayName = "Nav/Icon -- Shape 8";

FramerAWki6lWtj.defaultProps = {height: 24, width: 24};

addFonts(FramerAWki6lWtj, [])